export const faqRows: {
  id: string;
  title: string;
  description: string;
}[] = [
  {
    id: "01",
    title: "Jakie zabezpieczenia są stosowane podczas sesji?",
    description:
        "Wszystkie sesje odbywają się za pośrednictwem zabezpieczonych połączeń wideo, takich jak Google Meet, co zapewnia prywatność rozmów. System wykorzystuje szyfrowanie end-to-end, co gwarantuje, że dane przesyłane w trakcie sesji są bezpieczne i dostępne wyłącznie dla uczestników.",
  },
  {
    id: "02",
    title: "Co się stanie, jeśli mentor nie pojawi się na sesji?",
    description:
        "W przypadku, gdy mentor nie pojawi się na sesji, masz prawo do skontaktowania się z naszym działem wsparcia, który pomoże Ci w rozwiązaniu sytuacji. Możliwe jest przełożenie sesji lub uzyskanie pełnego zwrotu kosztów. W przypadkach rażącego naruszenia zasad współpracy, SkillGu ma prawo do interwencji. Przykłady obejmują brak obecności mentora, jakość sesji niezgodną z tematem, lub problemy techniczne, takie jak niska jakość dźwięku lub obrazu. Mentor ma obowiązek przełożyć sesję na inny termin, gdy takie problemy wystąpią.",
  },
  {
    id: "03",
    title: "Czy mogę uzyskać zwrot płatności?",
    description:
        "Tak, oferujemy możliwość zwrotu płatności w określonych przypadkach, takich jak brak pojawienia się mentora na sesji lub problemy techniczne. Szczegółowe informacje znajdziesz w naszej polityce zwrotów. SkillGu nie przechowuje pieniędzy – płatność jest przetwarzana bezpośrednio przez Stripe i trafia na konto mentora. SkillGu nie ponosi odpowiedzialności za problemy z płatnościami, które mogą wynikać z działań Stripe.",
  },
  {
    id: "04",
    title: "Jak przetwarzane są moje płatności?",
    description:
        "Wszystkie płatności przetwarzane są przez Stripe, globalnego lidera w dziedzinie bezpiecznych transakcji online. Twoje dane płatnicze są przechowywane i przetwarzane wyłącznie przez Stripe. SkillGu nie przechowuje żadnych środków – płatności trafiają bezpośrednio na konto mentora za pośrednictwem Stripe, co zapewnia najwyższy poziom bezpieczeństwa.",
  },
  {
    id: "05",
    title: "Gdzie mogę sprawdzić swoje potwierdzenia płatności?",
    description:
        "Potwierdzenia płatności są dostępne w Twoim profilu użytkownika, w sekcji 'Płatności'. Każda płatność jest automatycznie rejestrowana, a potwierdzenie wysyłane na Twój adres e-mail po zakończeniu transakcji.",
  },
  {
    id: "06",
    title: "Jak mogę anulować lub zmienić termin sesji?",
    description:
        "Aby anulować lub zmienić termin sesji, przejdź do swojego kalendarza i wybierz odpowiednią opcję przy danej sesji. Prosimy o dokonywanie zmian z wyprzedzeniem, zgodnie z polityką anulowania sesji mentora.",
  },
];
